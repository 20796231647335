import React from 'react';
import { RouteFormatter } from '../../routes';
import dogFencedImg from '../../assets/img/spot-types/dog-fully-fenced.png';
import dogHikingImg from '../../assets/img/spot-types/dog-hiking.png';
import dogIndoorImg from '../../assets/img/spot-types/dog-indoor.png';
import dogBeachImg from '../../assets/img/spot-types/dog-beach.png';
import dogFieldImg from '../../assets/img/spot-types/dog-field.png';
import dogSmallImg from '../../assets/img/spot-types/dog-small.png';
import dogWaterImg from '../../assets/img/spot-types/dog-water-park.png';
import dogPoolImg from '../../assets/img/spot-types/dog-pool.png';
import dogAgiImg from '../../assets/img/spot-types/dog-agility.png';
import PictureSet from '../../components/picture-set';
import {
    SvgAmenitiesAgilityEquipment,
    SvgAmenitiesBeach,
    SvgAmenitiesField,
    SvgAmenitiesHill,
    SvgAmenitiesIndoor,
    SvgAmenitiesKiddiePool,
    SvgAmenitiesSmallDog,
    SvgAmenitiesSwimmingPool,
} from '../../components/svg/amenities';
import SvgSrArrow from 'src/assets/svg/SvgSrArrow.svg';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';
import './render-other-types.scss';

export const otherSubs = [
    {
        id: 'WATER',
        icon: <SvgAmenitiesKiddiePool />,
        img: dogWaterImg,
        title: 'Dog water parks',
        desc: 'Many hosts have added water features like pools and/or are nearby lakes or rivers.',
        link: RouteFormatter.waterListings(),
    },
    {
        id: 'HIKING',
        icon: <SvgAmenitiesHill />,
        img: dogHikingImg,
        title: 'Dog hiking trails',
        desc: 'Some of our best spots have hiking trails in wonderful natural settings.',
        link: RouteFormatter.hikingListings(),
    },
    {
        id: 'INDOOR',
        icon: <SvgAmenitiesIndoor />,
        img: dogIndoorImg,
        title: 'Indoor dog parks',
        desc: 'A good alternative to do activities with your dogs on a rainy day and unpleasant weather in general.',
        link: RouteFormatter.indoorListings(),
    },
    {
        id: 'FENCED',
        icon: <SvgFenced />,
        img: dogFencedImg,
        title: 'Fully fenced dog parks',
        desc: 'Very popular choice, perfect to contain your dogs if they are escape artists or if they are not good at recall.',
        link: RouteFormatter.fencedListings(),
    },
    {
        id: 'BEACH',
        icon: <SvgAmenitiesBeach />,
        img: dogBeachImg,
        title: 'Dog beaches',
        desc: 'Some spots are conveniently located by the sea or have sand and other beach features.',
        link: RouteFormatter.beachListings(),
    },
    {
        id: 'AGILITY',
        icon: <SvgAmenitiesAgilityEquipment />,
        img: dogAgiImg,
        title: 'Dog agility parks',
        desc: 'Some hosts have implemented full agility courses to train your dogs in a private environment.',
        link: RouteFormatter.agilityListings(),
    },
    {
        id: 'FIELDS',
        icon: <SvgAmenitiesField />,
        img: dogFieldImg,
        title: 'Dog fields',
        desc: 'Many of the listed spots are large pastures, fields and have farm animals that can be sighted.',
        link: RouteFormatter.fieldsListings(),
    },
    {
        id: 'SMALL',
        icon: <SvgAmenitiesSmallDog />,
        img: dogSmallImg,
        title: 'Small dog parks',
        desc: 'Dog parks that specifically work for the needs of dogs that are smaller in size.',
        link: RouteFormatter.smallDogListings(),
    },
    {
        id: 'POOL',
        icon: <SvgAmenitiesSwimmingPool />,
        img: dogPoolImg,
        title: 'Dog swimming pools',
        desc: 'Many spots feature private swimming pools, perfect for dogs to enjoy and play.',
        link: RouteFormatter.poolListings(),
    },
];

const RenderOtherTypesSSR = ({ getSpecificSpots, seoSubCityType, sub = true }) => {
    const handleExplore = (e, item) => {
        if (seoSubCityType) {
            e.preventDefault();
            getSpecificSpots(item);
            return;
        }
        if (!item.link) {
            e.preventDefault();
            getSpecificSpots(item.page);
        }
    };

    return (
        <div className="sniff-other-types">
            <div className="l-container">
                <h2 className="snif-m1 snif-l1-pc snif-semibold text-dark mb-4">What types of private dog parks are available?</h2>
                {sub && (
                    <p className="snif-p text-dark mb-5 d-none d-md-block">
                        Sniffspot has different types of private dog parks to allow any dog to find their ideal spot!
                    </p>
                )}
                <div className="other-types-container">
                    {otherSubs.map((item, idx) => (
                        <a
                            key={idx}
                            className="other-types-item"
                            href={item.link || RouteFormatter.listings({})}
                            onClick={(e) => handleExplore(e, item)}
                        >
                            <PictureSet imgs={item.img} width={582} height={400} alt={`${item.title} thumbnail`} />
                            <div className="other-types-item-r">
                                <div>
                                    <div className="snif-p snif-semibold text-dark mb-1">{item.title}</div>
                                    <div className="snif-p snif-regular text-deep mb-2">{item.desc}</div>
                                </div>
                                <p className="types-item-r-link snif-p">
                                    Explore
                                    <SvgSrArrow />
                                </p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RenderOtherTypesSSR;
